<template>
  <OrganismNotificationsGrid />
</template>

<script>
import OrganismNotificationsGrid from '@/components/OrganismNotificationsGrid'

export default {
  title: vm => vm.$t('pages.notifications.title'),

  components: {
    OrganismNotificationsGrid,
  },
}
</script>
