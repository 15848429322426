import { helpers } from 'vuelidate/lib/validators'

const checked = helpers.withParams({ type: 'checked' }, value => !!value)

const url = helpers.regex('url', /^(www\.)?[-a-zA-Zа-яёА-ЯЁ0-9@:%._+~#=]{2,256}\.[a-zа-яё]{2,6}\/?$/)

const someRequired = (...fields) => helpers.withParams({ type: 'someRequired' }, (value, vm) => helpers.req(value) || fields.some(field => helpers.req(vm[field])))

export {
  checked,
  url,
  someRequired,
}
