<template>
  <MoleculeGrid
    @filterChange="filterChanged"
    :title="$t('notification.many.title')"
    :loading="state.loading"
    :columns="columns"
    :rows="preparedNotifications"
    :filters="filters"
    :pagination="true"
    :empty-message="$t('notification.empty')"
    :outline="false"
  >
    <template
      v-if="isStaff"
      slot="actions"
    >
      <AtomButton
        color="primary"
        class="d-none d-sm-inline-block"
        @click.prevent="modal.show = true"
        :disabled="state.loading"
      >
        <plus-icon />{{ $t('notification.create') }}
      </AtomButton>
      <AtomButton
        color="primary"
        class="d-sm-none"
        :aria-label="$t('notification.create')"
        @click.prevent="modal.show = true"
        :disabled="state.loading"
      >
        <plus-icon />
      </AtomButton>
      <MoleculeModal
        v-if="isStaff"
        :title="$t('notification.create')"
        :show="modal.show"
        @close="modal.show = false"
      >
        <template slot="body">
          <MoleculeForm
            :validator="$v"
            :disabled="state.submitLoading"
            :loading="state.submitLoading"
          >
            <AtomSelect
              class="mb-3"
              v-model="notification.userId"
              :options="users"
              :show-avatar="true"
              :reduce="user => user.id"
              value-label="fullname"
              :placeholder="$t('user.choose')"
              @input="notification.companyId = ''"
              :searchable="true"
            />
            <AtomDivider
              class="my-3"
              :color="($v.$anyDirty && ($v.notification.userId.$anyError || $v.notification.companyId.$anyError)) ? 'red' : null"
            >
              {{ $t('or') }}
            </AtomDivider>
            <AtomSelect
              class="mb-3"
              v-model="notification.companyId"
              :options="companies"
              value-label="name"
              :reduce="company => company.id"
              @input="notification.userId = ''"
              :placeholder="$t('company.choose')"
              :searchable="true"
            />
            <AtomSelect
              class="mb-3"
              v-model="notification.type"
              label="notification.type"
              :options="types"
              :reduce="type => type.value"
              :clearable="false"
              :placeholder="$t('notification.type.placeholder')"
              :searchable="true"
            />
            <AtomTextarea
              v-model="notification.message"
              label="notification.message"
              :rows="6"
            />
          </MoleculeForm>
        </template>
        <template slot="footer">
          <AtomButton
            class="me-auto"
            @click="modal.show = false"
          >
            {{ $t('action.cancel') }}
          </AtomButton>
          <AtomButton
            color="primary"
            class="ms-auto"
            :loading="state.submitLoading"
            :disabled="$v.$anyError || state.submitLoading"
            @click.prevent="submitHandler"
          >
            <plus-icon />{{ $t('action.create') }}
          </AtomButton>
        </template>
      </MoleculeModal>
    </template>
    <template #info="{ data }">
      <MoleculeAvatarDetails
        :subtitle="data.message"
        :avatar-color="data.type"
        :icon="data.icon"
      >
        <template #title>
          <template v-if="isStaff">
            <i18n
              v-if="data.user"
              path="notification.info.staff.user"
            >
              <template #name>{{ data.user.fullname }}</template>
              <template #type>{{ $t(`notification.types.${data.type}`) }}</template>
            </i18n>
            <i18n
              v-else-if="data.company"
              path="notification.info.staff.company"
            >
              <template #name>{{ data.company.name }}</template>
              <template #type>{{ $t(`notification.types.${data.type}`) }}</template>
            </i18n>
          </template>
          <template v-else>
            <i18n
              v-if="data.user"
              path="notification.info.user.self"
            >
              <template #type>{{ $t(`notification.types.${data.type}`) }}</template>
            </i18n>
            <i18n
              v-else-if="data.company"
              path="notification.info.user.company"
            >
              <template #name>{{ data.company.name }}</template>
              <template #type>{{ $t(`notification.types.${data.type}`) }}</template>
            </i18n>
          </template>
        </template>
      </MoleculeAvatarDetails>
    </template>
    <template #createdAt="{ data }">
      <time
        v-if="data.createdAt"
        class="text-nowrap text-muted"
      >
        <calendar-icon class="me-1" />{{ data.createdAt | date('datetime') }}
      </time>
    </template>
  </MoleculeGrid>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { someRequired } from '@/validators'

import MoleculeGrid from '@/components/MoleculeGrid'
import MoleculeModal from '@/components/MoleculeModal'
import MoleculeForm from '@/components/MoleculeForm'
import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'
import AtomSelect from '@/components/AtomSelect'
import AtomTextarea from '@/components/AtomTextarea'
import AtomButton from '@/components/AtomButton'
import AtomDivider from '@/components/AtomDivider'

export default {
  name: 'OrganismNotificationsGrid',

  components: {
    MoleculeGrid,
    MoleculeModal,
    MoleculeForm,
    MoleculeAvatarDetails,
    AtomSelect,
    AtomTextarea,
    AtomButton,
    AtomDivider,
  },

  data: () => ({
    notification: {
      message: '',
      type: '',
      userId: '',
      companyId: '',
    },

    modal: {
      title: '',
      show: false,
      submit: '',
    },

    state: {
      loading: true,
      submitLoading: false,
    },
  }),

  validations: {
    notification: {
      message: { required },
      type: { required },
      userId: { someRequired: someRequired('companyId') },
      companyId: { someRequired: someRequired('userId') },
    },
  },

  computed: {
    ...mapGetters(['users', 'companies', 'notifications', 'isStaff']),

    types () {
      return ['info', 'warning'].map(type => ({ value: type, label: this.$t(`notification.types.${type}`) }))
    },

    columns () {
      return [
        { key: 'idx', name: '№', width: 5 },
        { key: 'info' },
        { key: 'createdAt', name: this.$t('date.created.label'), width: 20 },
      ]
    },

    filters () {
      const filters = []

      if (this.isStaff) {
        filters.push({ key: 'userId', label: 'user.fullname', placeholder: this.$t('user.label') })
        filters.push({ key: 'companyId', label: 'company.name', placeholder: this.$t('company.label') })
        filters.push({ key: 'type', label: data => this.$t(`notification.types.${data.type}`), placeholder: this.$t('notification.type.label') })
      }

      return filters
    },

    preparedNotifications () {
      return this.notifications.map(item => {
        const icons = {
          warning: 'alert-triangle',
          info: 'info-circle',
        }
        return {
          ...item,
          icon: icons[item.type] || null,
        }
      })
    },
  },

  async mounted () {
    try {
      await this.fetchNotifications()
      if (this.isStaff) {
        await this.fetchUsers()
        await this.fetchCompanies()
      }
    } catch {} finally {
      this.state.loading = false
    }
  },

  methods: {
    ...mapActions(['fetchUsers', 'fetchCompanies', 'fetchNotifications', 'createNotification']),

    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.state.submitLoading = true
        await this.createNotification(this.notification)
        this.notification.message = this.notification.type = this.notification.userId = this.notification.companyId = ''
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('notification.success.message'),
          type: 'success',
        })
        this.$v.$reset()
        this.modal.show = false
      } catch {} finally {
        this.state.submitLoading = false
      }
    },

    filterChanged ({ item, filter }) {
      if (item.key === 'userId' && filter.companyId) {
        filter.companyId = ''
      } else if (item.key === 'companyId' && filter.userId) {
        filter.userId = ''
      }
    },
  },
}
</script>
