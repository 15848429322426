<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
      :counter="counter"
      :total="value.length"
      :limit="!!v && v.$params.maxLength ? v.$params.maxLength.max : null"
    >
      <template
        v-if="!!$slots.labelDescription"
        slot="description"
      >
        <slot name="labelDescription" />
      </template>
    </AtomLabel>
    <textarea
      class="form-control"
      :id="id"
      :rows="rows"
      :cols="cols"
      :placeholder="preparedPlaceholder"
      :class="inputClasses"
      :disabled="isDisabled"
      :value="value"
      @input="updateValue($event.target.value)"
    />
    <small
      v-if="description || !!$slots.description"
      class="form-hint"
    >
      <slot name="description">{{ description }}</slot>
    </small>
    <span
      v-if="error"
      class="invalid-feedback"
      v-text="error"
    />
  </div>
</template>

<script>
import AtomLabel from '@/components/AtomLabel'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomTextarea',

  components: {
    AtomLabel,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    rows: Number,
    cols: Number,

    counter: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
